import React, { PureComponent } from 'react'
import { canSocialPost, postIsMine, getDeviceLabel, shouldGetDevice, deviceIsMine, hasWebcam, getDeviceSharePath, isAdmin, postIsExpired, WIDGET_CONFIG, getDeviceShareLink, socialDevices, centerPointForGeo } from '../../common/ambient'
import { Link, withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { filter, concat, path } from 'ramda'
import bindAllActions from '../../common/bindAllActions'
import classNames from 'classnames'
import {
  Avatar
} from '../user'
import {
  WebcamWidget,
  TempWidget,
  WindWidget,
  RainWidget
} from './'
import { Post, Comments } from '../social'
import {
  FromNow,
  Like,
  Loader,
  FavStar,
  Share
} from '../../components'
import circleToPolygon from 'circle-to-polygon'
import { circleToPolygonSafe } from '../../common/ambient/geo'
import DeviceCardHeader from './DeviceCardHeader'

/**
 * This is becoming a generic "Card"
 */

class DevicePopup extends PureComponent {
  static propTypes = {
    currentDevice: PropTypes.object,
    className: PropTypes.string,
    onMouseLeave: PropTypes.func,
    onClick: PropTypes.func,
    skipFetch: PropTypes.bool,
    layerParam: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool
    ])
  }

  state = {
    loading: false,
    utilityOpen: false
  }

  componentDidMount () {
    this._fetchDevice()
  }

  componentDidUpdate () {
    this._fetchDevice()
  }

  _fetchDevice () {
    const { device, currentDevice, deviceActions, skipFetch } = this.props
    if (!skipFetch && shouldGetDevice(device, currentDevice._id, 5)) {
      deviceActions.getDevice(currentDevice._id)
    }
  }

  _onClick () {
    const { onClick } = this.props
    this.setState({
      loading: true
    })
    if (onClick) {
      onClick(this._currentDevice())
    }
    setTimeout(() => {
      this.setState({
        loading: false
      })
    }, 1000)
  }

  _ago () {
    const { currentDevice, layerParam, post } = this.props
    const { webcamTimestamp } = this.state
    if (layerParam === 'webcam') {
      return webcamTimestamp ? <FromNow dateutc={webcamTimestamp.valueOf()} /> : null
    } else if (layerParam === 'social') {
      return post ? <FromNow dateutc={post.createdAt} /> : null
    }
    return <FromNow dateutc={path(['lastData', 'dateutc'], currentDevice)} />
  }

  _buttons () {
    const { socialActions, layerParam, user, post } = this.props
    const { utilityOpen } = this.state
    if (layerParam !== 'social') return null
    const btns = []
    const close = () => this.setState({ utilityOpen: false })
    const share = this._share({ onClick: close, key: 'share' })
    if (share) {
      btns.push(share)
    }
    if ((postIsMine(user, post) || isAdmin(user)) && !postIsExpired(post)) {
      btns.push(
        <a
          key='expire'
          onClick={() => {
            if (window.confirm('This post will no longer show up on the map. Proceed anyway?')) {
              socialActions.patchPost(post._id, { expiresAt: Date.now() })
              close()
            }
        }}>
          Expire Post
        </a>
      )
    }
    if (postIsMine(user, post)) {
      btns.push(
        <a
          key='trash'
          onClick={() => {
            if (window.confirm('Delete Post?')) {
              socialActions.patchPost(post._id, { status: 'trash' })
              close()
            }
          }}
          className='remove'
        >Delete Post
        </a>
      )
    }
    if (isAdmin(user)) {
      btns.push(
        <a
          key='remove'
          onClick={() => {
            if (window.confirm('Ban Post?')) {
              socialActions.patchPost(post._id, { status: 'banned' })
              close()
            }
          }}
          className='remove'
        >Remove
        </a>
      )
      const isBoosted = post.status === 'boosted'
      btns.push(
        <a
          key='boost'
          onClick={() => {
            // window.alert('cannot boost right now ¯\_(ツ)_/¯')
            // return
            if (window.confirm(isBoosted ? 'Unboost Post?' : 'Boost Post?')) {
              const centerGeo = path(['data', 'centerGeo'], post) || centerPointForGeo(post.geo)
              let boostedRadiusMiles = 3000
              if (!isBoosted) {
                const mi = window.prompt('Set post radius (miles):', '3000')
                if (mi) {
                  boostedRadiusMiles = parseInt(mi)
                }
              }
              const patch = {
                status: isBoosted ? 'normal' : 'boosted',
                geo: circleToPolygonSafe(centerGeo.coordinates, (isBoosted ? 100 : boostedRadiusMiles))
              }
              if (!post.data.centerGeo) {
                patch.data = {
                  ...post.data,
                  centerGeo: post.geo
                }
              }
              socialActions.patchPost(post._id, patch)
              close()
            }
          }}
          className='remove'
        >{isBoosted ? 'Unboost Post' : 'Boost Post'}
        </a>
      )
      if (post.tags && post.tags.includes('suggestion box')) {
        btns.push(
          <a
            key='remove'
            onClick={() => {
              if (window.confirm('Move to regular social timeline?')) {
                socialActions.patchPost(post._id, { tags: [] })
                close()
              }
            }}
            className='remove'
          >Not a Suggestion
          </a>
        )
      }
    }
    if (btns.length < 1) return null
    return (
      <div className={classNames('utility', { open: utilityOpen })}>
        <a className='dots' onClick={() => this.setState({ utilityOpen: !utilityOpen })} />
        <div>
          {btns}
        </div>
      </div>
    )
  }

  _share (addProps = {}) {
    const { userActions, user, device, layerParam, post } = this.props
    const { devices } = device
    let sharePaths = [
      ['layerParam'],
      ['currentDevice', 'unitSetting', 'image'],
      ['currentDevice', 'info', 'name'],
      ['currentDevice', 'info', 'coords', 'location']
    ]
    if (layerParam === 'social') {
      sharePaths = concat(sharePaths, [
        ['post', 'image'],
        ['post', 'text'],
        ['post', '_id'],
        ['post', 'createdAt']
      ])
    } else if (layerParam === 'webcam') {
      const postableDevices = socialDevices(user, device)
      if (postableDevices.length > 0) {
        return (
          <span className='component-share'>
            <a onClick={evt => {
              evt.preventDefault()
              userActions.doModal({ type: 'create-post', data: { 
                currentDevice: postableDevices[0],
                coords: this._currentDevice().info.coords,
                webcamSnapshot: this._currentDevice()._id
              }})
            }} className="share" /></span>
        )
      }
      sharePaths = false
    } else if (/rain/.test(layerParam)) {
      sharePaths = concat(sharePaths, WIDGET_CONFIG.rain.share.paths)
    } else if (/wind/.test(layerParam)) {
      sharePaths = concat(sharePaths, WIDGET_CONFIG.wind.share.paths)
    } else if (!layerParam) {

    // tempf
    } else {
      sharePaths = concat(sharePaths, WIDGET_CONFIG.temp.share.paths)
    }
    if (!sharePaths) return null
    const currentDevice = this._currentDevice()
    const deviceLabel = getDeviceLabel(currentDevice)
    const deviceLabelPieces = deviceLabel.split(',')
    const shareLink = getDeviceShareLink(currentDevice) + (layerParam === 'social' ? '/social' : '')
    return (
      <Share 
        currentDevice={currentDevice}
        layerParam={layerParam}
        post={post}
        title={deviceLabel}
        description="AmbientWeather.net: Weather is Personal"
        component="DevicePopup"
        redirect={shareLink}
        paths={sharePaths}
        {...addProps}
      />
    )
  }

  _like () {
    const { layerParam, post } = this.props
    if (layerParam !== 'social') return null
    return <Like type='post' thing={post} />
  }

  _currentDevice () {
    const { user, device } = this.props
    const { dashboardDevice, deviceCache } = device
    // use cache cuz it'll be most fresh
    let currentDevice = deviceCache[this.props.currentDevice._id]
    if (!currentDevice) {
      // maybe its the dashboard device
      if (dashboardDevice && this.props.currentDevice._id === dashboardDevice._id) {
        currentDevice = dashboardDevice
      // then we should have passed it in
      } else {
        currentDevice = this.props.currentDevice
      }
    }
    if (deviceIsMine(device, currentDevice._id) && path(['info', 'image'], user)) {
      // put my avatar in the unitSettings
      // this is used for sharing
      currentDevice.unitSetting = currentDevice.unitSetting || {}
      currentDevice.unitSetting.image = path(['info'], user).image
    }
    return Object.assign({}, currentDevice, { settings: null })
  }

  render () {
    const { history, post, onClick, className, userActions, deviceActions, user, layerParam, onMouseLeave, device, id } = this.props
    const { loading, height } = this.state
    const currentDevice = this._currentDevice()
    let widget = <TempWidget slim currentDevice={currentDevice} />
    if (layerParam === 'social') {
      widget = <Post post={post} />
    } else if (layerParam === 'webcam') {
      widget = <WebcamWidget onTimestamp={webcamTimestamp => this.setState({ webcamTimestamp })} currentDevice={currentDevice} userActions={userActions} />
    } else if (/rain/.test(layerParam)) {
      widget = <RainWidget currentDevice={currentDevice} />
    } else if (/wind/.test(layerParam)) {
      widget = <WindWidget currentDevice={currentDevice} />
    } else if (!layerParam) {
      widget = null
    }
    const shareLink = getDeviceSharePath(currentDevice) + (layerParam === 'social' ? '/social' : '')
    let link = (
      <a
        onClick={() => {
          deviceActions.setDashboardDevice(null)
          history.replace(shareLink)
      }} className='btn btn-primary'>View Dashboard</a>
    )
    if (onClick) {
      link = <a onClick={this._onClick.bind(this)} className={classNames('btn btn-primary', { loading })}>{loading ? <Loader className='white' /> : 'View Dashboard'}</a>
    }
    return (
      <div
        id={id}
        onMouseLeave={() => {
          if (onMouseLeave) {
            // onMouseLeave()
          }
        }}
        className={classNames('device-device-popup', {
          'not-mine': !deviceIsMine(device, currentDevice._id),
          'on-dash': currentDevice._id === path(['dashboardDevice', '_id'], device),
          'has-img': post && post.image,
          boosted: post && post.status === 'boosted',
          short: height < 250
        }, layerParam, className)}
        ref={ref => this.ref = ref}
      >
        <DeviceCardHeader
          post={post}
          currentDevice={currentDevice}
        >
          <div className='ago'>
            {this._buttons()}
            {this._ago()}
          </div>
        </DeviceCardHeader>
        <div className='content'>
          {widget}
        </div>
        <div className='bottom'>
          {this._like()}
          {link}
          {this._share()}
        </div>
        {layerParam === 'social' ? <Comments post={post} /> : null}
      </div>
    )
  }
}

export default bindAllActions(withRouter(DevicePopup))
DevicePopup.displayName = 'DevicePopup'
