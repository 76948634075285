import React, { PureComponent, useMemo, useEffect } from 'react'
import { Link, withRouter } from 'react-router-dom'
import classNames from 'classnames'
import { InView } from 'react-intersection-observer'
import bindAllActions from '../../common/bindAllActions'
import PropTypes from 'prop-types'
import { equals, map, sort, pluck, path, last, pipe, concat, uniqBy } from 'ramda'
import { DevicePopup } from '../device'
import {
  Loader
} from '../../components'
import { trackEvent, deviceIsMine, dateVal, getDeviceSharePath, isAdmin, isDev, latLonDistance, pathsChanged, isLoggedIn, getStorage, setStorage } from '../../common/ambient'
import * as turf from '@turf/helpers'
import booleanPointInPolygon from '@turf/boolean-point-in-polygon'
import DropdownMenu from '../common/DropdownMenu'
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"


const KM = 161

class MyTimeline extends PureComponent {
  static propTypes = {
    socialActions: PropTypes.object,
    currentDevice: PropTypes.object,
    postId: PropTypes.string,
    postIds: PropTypes.array,
    geo: PropTypes.object, // geo json
    onTabChange: PropTypes.func,
    tab: PropTypes.string,
  }

  state = {
    fetchedPage: 0,
    page: 1,
    tab: 'all',
    isAtTop: false,
    sort: 'date',
    layout: getStorage('my-timeline-layout') || 'masonry',
    postsToShow: []
  }

  constructor (props) {
    super(props)
    this.handleScroll = ::this.handleScroll
    this._updatePosts = ::this._updatePosts
    this._onPostClick = ::this._onPostClick
  }

  componentDidMount () {
    this._mounted = true
    this._fetchPage()
    this._fetchBoosted()
    window.addEventListener('scroll', this.handleScroll)
  }

  shouldComponentUpdate(nextProps, nextState) {
    // State changes that should trigger update
    if (this.state.page !== nextState.page ||
        this.state.tab !== nextState.tab ||
        this.state.sort !== nextState.sort ||
        this.state.layout !== nextState.layout ||
        this.state.postsToShow !== nextState.postsToShow ||
        this.state.fetching !== nextState.fetching ||  // For loading state
        this.state.isAtTop !== nextState.isAtTop) {    // For scroll behavior
      return true;
    }
  
    // Props changes that should trigger update
    if (this.props.currentDevice !== nextProps.currentDevice ||
        this.props.postId !== nextProps.postId ||
        this.props.geo !== nextProps.geo ||
        this.props.social.patchPostPending !== nextProps.social.patchPostPending ||
        this.props.postIds !== nextProps.postIds) {
      return true;
    }
  
    // Check for social.allPosts changes
    const currentPosts = this.props.social.allPosts || [];
    const nextPosts = nextProps.social.allPosts || [];
    if (currentPosts.length !== nextPosts.length) {
      return true;
    }
  
  
    // Check for changes in favorites
    const currentFavs = this.props.social.favs || [];
    const nextFavs = nextProps.social.favs || [];
    if (currentFavs.length !== nextFavs.length) {
      return true;
    }
  
    // Check for user changes that might affect post visibility
    if (this.props.user !== nextProps.user) {
      return true;
    }
  
    // If none of the above conditions are met, don't update
    return false;
  }

  componentDidUpdate (prevProps, prevState) {
    this._fetchBoosted()
    // new device or postId or geo or logging in
    if (pathsChanged(this.props, prevProps, [['currentDevice', '_id'], ['postId'], ['geo'], ['user', 'userChecked']]) ||
    pathsChanged(this.state, prevState, [['tab']]) // tab changed
    ) {
      this._setState({
        page: 1,
        fetchedPage: 0
      }, this._fetchPage.bind(this))
    }
    if (this.props.onTabChange && prevState.tab !== this.state.tab) {
      this.props.onTabChange(this.state.tab)
    }
    if (this.props.tab) {
      this.setState({ tab: this.props.tab })
    }
  }

  componentWillUnmount () {
    this._mounted = false
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll = () => {
    if (this._mounted) {
      const element = document.querySelector('.social-my-timeline')
      if (element) {
        const rect = element.getBoundingClientRect()
        const isAtTop = rect.top <= 111
        if (this.state.isAtTop !== isAtTop) {
          this.setState({ isAtTop })
        }
      }
    }
  }

  _setState (what, cb) {
    if (this._mounted) {
      this.setState(what, cb)
    }
  }

  _fetchBoosted () {
    const { social, socialActions } = this.props
    const { fetchedBoosted } = social
    if (fetchedBoosted) return
    socialActions.fetchPosts({
      dataKey: 'boosted',
      $limit: 20,
      status: 'boosted',
      $sort: {
        createdAt: -1
      }
    })
  }

  _showLocationBasedPosts () {
    const { currentDevice } = this.props
    // logged in user
    return this.state.tab === 'location' &&
      ((currentDevice && path(['info', 'coords', 'geo'], currentDevice)) || this._isSocialPage())
  }

  _isUserDashboard () {
    return !this._isSocialPage()
  }

  _fetchPage () {
    if (!this.props.user.userChecked) return
    const { fetchedPage, page, tab } = this.state
    const { geo, history, currentDevice, socialActions, postId, postIds, social, device, user } = this.props
    const { allPosts } = social
    if (fetchedPage === page || page === -1) return
    const perPage = 30
    this._setState({
      fetchedPage: page,
      fetching: true
    }, () => {
      const commonArgs = {
        $limit: perPage,
        $skip: perPage * (page - 1),
        $sort: {
          createdAt: -1
        }
      }
      let args = Object.assign({}, commonArgs)
      if (currentDevice || geo) {
        if (this._showLocationBasedPosts()) {
          args = Object.assign({
            geo: {
              $near: {
                $geometry: geo || currentDevice.info.coords.geo,
                $maxDistance: KM * 1000
              }
            },
            status: 'published',
            expiresAt: { $lt: 14806304734411 }
          }, commonArgs, {
            $sort: {
              expiresAt: -1
            }
          })
        } else if (tab === 'user' && currentDevice) {
          args.deviceId = currentDevice._id
        }
      }
      if (postId) {
        args = {
          _id: postId
        }
      }
      if (postIds) {
        args = {
          _id: { $in: postIds.filter(pId => !pluck('_id', allPosts).includes(pId)).slice(0, 20) }
        }
      }
      socialActions.fetchPosts(args)
        .then(_res => {
          const st8 = {
            fetching: false
          }
          if (_res) {
            const res = _res.data || _res
            if (res.length === 0) {
              st8.page = -1
            // we did find posts at this is the logged in user's dasboard
            // and it's the first page
            } else if (this._isUserDashboard() && page === 1 && this.state.tab === 'location') {
              // use createDates to find personalized posts
              const createDates = map(dateVal, pluck('createdAt', res))
              if (createDates.length > 0) {
                const args = {
                  expiresAt: {
                    $gte: Math.min(...createDates)
                  },
                  $sort: {
                    expiresAt: -1
                  },
                  personalized: currentDevice.userId
                }
                socialActions.fetchPosts(args)
              }
            }
          }
          // my stuff
          if (this._isMyStuff()) {
            const args = Object.assign({}, commonArgs, {
              mine: user.info._id
            })
            socialActions.fetchPosts(args)
          }
          this._setState(st8)
          // make sure a single post belongs to this device
          if (postId && this._posts().length < 1) {
            history.push(this._socialTabPath())
          }
        })
    })
  }

  _isMyStuff () {
    const { tab } = this.state
    return tab === 'user' && this._showMyStuff()
  }

  _showMyStuff () {
    const { currentDevice, device, user } = this.props
    return (
      (this._isUserDashboard() && currentDevice && deviceIsMine(device, currentDevice._id)) ||
      (this._isSocialPage() && isLoggedIn(user))
    )
  }

  _socialTabPath () {
    const pieces = window.location.pathname.split('/')
    if (last(pieces) === 'social') {
      return window.location.pathname
    }
    return pieces.slice(0, -1).join('/')
  }


  _updatePosts () {
    const { social, user, geo, currentDevice, postId, postIds } = this.props
    const { allPosts, favs } = social
    const { tab } = this.state
    const whitelistedDeviceIds = this._isUserDashboard() ? (favs || []).map(f => f.to._id) : []
    const theSort = sort((a, b) => {
      if (this.state.sort === 'popularity') {
        return (b.likes || 0) - (a.likes || 0)
      } else {
        const prop = this._isMyStuff() ? 'updatedAt' : 'createdAt'
        return dateVal(b[prop]) - dateVal(a[prop])
      }
    })
    let ret = allPosts
      .filter(p => {
        if (postIds) return postIds.includes(p._id)
        if (tab !== 'suggestions' && p.tags && p.tags.includes('suggestion box')) return false
        if (tab === 'suggestions') return p.tags && p.tags.includes('suggestion box')
        // currentDevice will be here for the dashboard
        if (!currentDevice && !geo) return true
        // if (p.status === 'boosted') return true
        if (tab === 'all') {
          if (p.text) return true
          if (p.comments) return true
          return !/widget/.test(p.type)
        } else if (this._showLocationBasedPosts()) {
          const postGeo = path(['geo'], p)
          const deviceGeo = geo || path(['info', 'coords', 'geo'], currentDevice)
          let properDistance = false
          if (postGeo && deviceGeo) {
            if (postGeo.type === 'Point') {
              properDistance = latLonDistance(postGeo.coordinates[1], postGeo.coordinates[0], deviceGeo.coordinates[1], deviceGeo.coordinates[0], 'K') < KM
            } else if (postGeo.type === 'Polygon') {
              properDistance = booleanPointInPolygon(turf.point(deviceGeo.coordinates), turf.polygon(postGeo.coordinates))
            }
          }
          return (currentDevice && p.userId === currentDevice.userId) || properDistance
        // } else if (tab === 'suggestions') {
        //   return p.tags && p.tags.includes('suggestion box')
        } else if (tab === 'imagery') {
          return p.image || p.video
        } else {
          if (currentDevice && p.deviceId === currentDevice._id) return true
          if (this._showMyStuff() && p._mine) return true
        }
      })
      .filter(p => postId ? postId === p._id : true)
      .filter(p => isAdmin(user) || ['published', 'boosted'].includes(p.status))

    ret = theSort(ret)
    const currentPostsDateRangeFilter = p => ret[ret.length - 1] && dateVal(p.createdAt) >= dateVal(ret[ret.length - 1].createdAt)
    // add fav devices posts ---------------------------- v this is a shortcut for making sure it's their own device
    if (this._isUserDashboard() && tab === 'location' && this._showMyStuff()) {
      const favPosts = allPosts
        .filter(p => whitelistedDeviceIds.includes(p.deviceId))
        .filter(currentPostsDateRangeFilter)
      ret = concat(ret, favPosts)
    }
    // add boosted posts
    if (tab !== 'user' && tab !== 'suggestions') {
      const boostedPosts = allPosts.filter(p => p.status === 'boosted')
      if (ret.length < 2) {
        ret = pipe(
          concat(boostedPosts),
          uniqBy(p => p._id),
          theSort
        )(ret)
      } else {
        ret = pipe(
          concat(boostedPosts.filter(currentPostsDateRangeFilter)),
          uniqBy(p => p._id),
          theSort
        )(ret)
      }
    }
    return ret
  }

  _isSocialPage () {
    return this.props.geo
  }
  _onPostClick (theDevice) {
    const { deviceActions, history } = this.props
    deviceActions.setDashboardDevice(theDevice)
    history.push(getDeviceSharePath(theDevice) + '/social')
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }

  render () {
    const { user, deviceActions, history, currentDevice, postId, postIds, geo } = this.props
    const { tab, fetching, page, isAtTop } = this.state
    // if (!currentDevice) return null
    const items = ['all', 'location', 'imagery']
    const postsToShow = this._updatePosts()
    if (isLoggedIn(user)) {
      items.push(['user', this._showMyStuff() ? 'My Activity' : 'Station only'])
      if (isAdmin(user)) {
        items.push('suggestions')
      }
    }
    let tabs = <DropdownMenu
      items={items}
      selected={tab}
      onClick={tab => {
        this.setState({ tab })
        trackEvent('social', 'tab', tab)
      }}
      before={tab === 'all' ? 'Showing:' : 'Filter By:'}
    />
    if (!fetching && postId) {
      tabs = <Link className='btn btn-primary show-all' to={this._socialTabPath()}>Show All Posts</Link>
    }
    if (!currentDevice && !geo) {
      tabs = null
    }
    const layout = this.props.layout || this.state.layout

    return (
      <div className={classNames('social-my-timeline', { 'at-top': isAtTop, single: layout === 'list', 'force-list': this.props.layout === 'list' })}>
        <div className='controls'>
          {tabs}
          <ul className='layout-switcher'>
            <li>
              <a className={classNames('masonry', { active: layout === 'masonry' })} onClick={() => {
                this.setState({ layout: 'masonry' })
                setStorage('my-timeline-layout', 'masonry')
                trackEvent('social', 'layout', 'masonry')
              }} />
            </li>
            <li>
              <a className={classNames('list', { active: layout === 'list' })} onClick={() => {
                this.setState({ layout: 'list' })
                setStorage('my-timeline-layout', 'list')
                trackEvent('social', 'layout', 'masonry')
              }} />
            </li>
          </ul>
          <DropdownMenu
            className='sort'
            items={['date', 'popularity']}
            selected={this.state.sort}
            onClick={srt => this.setState({ sort: srt })}
            before='Sort:'
          />
        </div>
        <Posts
          postsToShow={postsToShow}
          singleColumn={layout === 'list'}
          onPostClick={this._onPostClick}
          fetching={fetching}
        />
        <InView
          onChange={(inView, info) => {
            if (inView && !fetching && page !== -1 && !postId && !postIds) {
              this._setState({
                page: page + 1
              }, this._fetchPage.bind(this))
            }
          }}
        />
      </div>
    )
  }
}


const Posts = React.memo(function Posts({ postsToShow, onPostClick, fetching, singleColumn }) {

  const masonryContent = useMemo(() => {
    
    if (postsToShow.length === 0) {
      return <p className='no-posts'>{fetching ? 'Loading...' : 'No posts yet'}</p>
    }
    const posts = postsToShow.map(p => (
      <DevicePopup
        id={p._id}
        currentDevice={{ _id: p.deviceId }}
        layerParam='social'
        post={p}
        key={p._id}
        onClick={onPostClick}
      />
    ))
    if (singleColumn) {
      return posts
    }
    return (
      <ResponsiveMasonry columnsCountBreakPoints={{1: 1, 767: 2, 1280: 3}}>
        <Masonry gutter="15px">
          {posts}
        </Masonry>
      </ResponsiveMasonry>
    )
  }, [postsToShow, fetching, onPostClick, singleColumn])

  return (
    <div className='posts'>
      {masonryContent}
      {fetching && <Loader />}
    </div>
  )
}, (prevProps, nextProps) => {
  const areEqual = prevProps.fetching === nextProps.fetching &&
                  prevProps.onPostClick === nextProps.onPostClick &&
                  prevProps.singleColumn === nextProps.singleColumn &&
                  equals(prevProps.postsToShow, nextProps.postsToShow);
  
  return areEqual;
})

export default bindAllActions(withRouter(MyTimeline))
MyTimeline.displayName = 'MyTimeline'
